// initial state
const state = () => ({
    prefecture: null
})

const getters = {
    Prefecture: (state) => {
        return state.prefecture
    }
}

const actions = {
    
}

// update methods
const mutations = {
    SetPrefecture (state, prefecture) {
        state.prefecture = prefecture
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
  