<template>
  <bread-crumb list-classes="breadcrumb-links breadcrumb-dark">
    <BreadCrumbItem v-if="matched.length>0">
      <li class="breadcrumb-item">
        <router-link to="/">
          <i class="fas fa-home"></i>
        </router-link>
      </li>
    </BreadCrumbItem>
    <BreadCrumbItem
      v-for="(route) in matched"
      :key="route.path"
      style="display:inline-block"
    >
      <router-link :to="{ name: route.name }">
        {{ route.name }}
      </router-link>
    </BreadCrumbItem>
  </bread-crumb>
</template>

<script>
  import BreadCrumb from './Breadcrumb';
  import BreadCrumbItem from './BreadcrumbItem';

  export default {
    name: 'route-breadcrumb',
    components: {
      BreadCrumb,
      BreadCrumbItem
    },
    computed: {
      matched() {
        let filtered = this.$route.matched.filter( item => item.name.indexOf('_')!=0 && item.name != this.$route.name )
        return filtered
      }
    },
    methods: {
      getBreadName(route) {
        return route.name;
      }
    }
  };
</script>

<style scoped></style>
