import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import AuthLayout from '@/views/Layout/AuthLayout.vue';
import NotFound from '@/views/GeneralViews/NotFoundPage.vue';

const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '@/views/Pages/Dashboard.vue');
const Login = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Login.vue');
const Register = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Register.vue');
const Users = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Users/Index.vue');
const Counters = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Stores/Counters.vue');
const CountersReport = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Reports/Counters.vue');
const Areas = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Stores/Areas.vue');
const AreasReport = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Reports/Areas.vue');
const Stores = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Stores/Stores.vue');
const StoresReport = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Reports/Stores.vue');
const OnlineStores = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Stores/OnlineStores.vue');
const OnlineStoresReport = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Reports/OnlineStores.vue');
const YearTotalReport = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Reports/YearTotal.vue');
const Songcai = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Songcai/Table.vue');

let authPages = {
    path: '/',
    component: AuthLayout,
    redirect: '/login',
    children: [
      {
        path: 'login',
        name: '登录',
        component: Login
      },
      {
        path: 'register',
        name: '注册',
        component: Register
      }
    ]
  };

const routes = [
    authPages,
    {
      path: '/',
      component: DashboardLayout,
      redirect: '/dashboard',
      name: '_Dashboard',
      children: [
        {
          path: 'dashboard',
          name: '仪表盘',
          component: Dashboard
        },
        {
          path: 'profiles',
          name: '账号资料',
          component: () => import(/* webpackChunkName: "profiles" */ '@/views/Pages/Profiles.vue')
        },
        {
          path: 'users',
          name: '账号列表',
          component: Users
        },
        {
          path: 'plans',
          name: '三专年度计划表',
          component: () => import(/* webpackChunkName: "plans" */ '@/views/Pages/Plans/Index.vue')
        },
        {
          path: 'counters',
          name: '专柜列表',
          component: Counters
        },
        {
          path: 'countersreport',
          name: '专柜报表',
          component: CountersReport
        },
        {
          path: 'areas',
          name: '专区列表',
          component: Areas
        },
        {
          path: 'stores',
          name: '专馆列表',
          component: Stores
        },
        {
          path: 'onlinestores',
          name: '线上专馆',
          component: OnlineStores
        },
        {
          path: 'areasreport',
          name: '专区报表',
          component: AreasReport
        },
        {
          path: 'storesreport',
          name: '专馆报表',
          component: StoresReport
        },
        {
          path: 'onlinestoresreport',
          name: '线上专馆报表',
          component: OnlineStoresReport
        },
        {
          path: 'totalreport',
          name: '三专统计',
          component: YearTotalReport
        },
        {
          path: 'songcai/:group',
          name: '送菜上门服务名单',
          component: Songcai
        }
      ]
    },
    { path: '*', component: NotFound }
  ];
  
  export default routes;