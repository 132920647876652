//import Vue from 'vue';
//import axios from 'axios';
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
import {userMixin} from './userMixin'

// vuex store setup
import store from './store'
// router setup
import router from './routes';

// plugin setup
Vue.use(DashboardPlugin);

axios.defaults.baseURL = '/backend/api';
axios.defaults.withCredentials = true;
Vue.prototype.axios = axios
Vue.mixin(userMixin);

/* eslint-disable no-new */
new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
