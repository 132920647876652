<template>
  <div class="wrapper">
    <notifications></notifications>
    <side-bar>
      <template slot="links">
        <sidebar-item :link="{ name: '仪表盘', icon: 'ni ni-chart-pie-35 text-muted', path: '/dashboard' }">
        </sidebar-item>
        <sidebar-item :link="{ name: '账号管理', icon: 'ni ni-single-02 text-muted', path: '/users' }" v-if="isSuper && hasMUS">
        </sidebar-item>
        <sidebar-item :link="{ name: '三专年度计划表', icon: 'ni ni-paper-diploma text-muted', path: '/plans' }" v-if="isSuper && hasMP">
        </sidebar-item>
        <sidebar-item :link="{ name: '三专管理', icon: 'ni ni-shop text-muted' }" v-if="!isReportReader && !deny3Z">
          <sidebar-item :link="{ name: '线上专馆', path: '/onlinestores' }"/>
          <sidebar-item :link="{ name: '专馆', path: '/stores' }"/>
          <sidebar-item :link="{ name: '专区', path: '/areas' }"/>
          <sidebar-item :link="{ name: '专柜', path: '/counters' }"/>
        </sidebar-item>
        <sidebar-item :link="{ name: '统计报表', icon: 'ni ni-chart-bar-32 text-muted' }" v-if="!deny3Z">
          <sidebar-item :link="{ name: '线上专馆', path: '/onlinestoresreport' }"/>
          <sidebar-item :link="{ name: '专馆', path: '/storesreport' }"/>
          <sidebar-item :link="{ name: '专区', path: '/areasreport' }"/>
          <sidebar-item :link="{ name: '专柜', path: '/countersreport' }"/>
          <sidebar-item :link="{ name: '三专统计', path: '/totalreport' }" v-if="isSuper" />
        </sidebar-item>
        <sidebar-item :link="{ name: '送菜上门服务名单', icon: 'ni ni-delivery-fast text-muted' }" v-if="hasVSC">
          <sidebar-item :link="{ name: '外派干部', path: '/songcai/扶贫干部' }" :key="'fupinganbu'" />
          <sidebar-item :link="{ name: '援港医务人员', path: '/songcai/援港医务人员' }" :key="'yuanganyiwurenyuan'" />
        </sidebar-item>
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
  /* eslint-disable */
  import PerfectScrollbar from 'perfect-scrollbar';
  import 'perfect-scrollbar/css/perfect-scrollbar.css';

  function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
  }

  function initScrollbar(className) {
    if (hasElement(className)) {
      new PerfectScrollbar(`.${className}`);
    } else {
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className);
      }, 100);
    }
  }

  import DashboardNavbar from './DashboardNavbar.vue';
  import ContentFooter from './ContentFooter.vue';
  import { FadeTransition } from 'vue2-transitions';
  //import axios from 'axios';

  export default {
    components: {
      DashboardNavbar,
      ContentFooter,
      FadeTransition
    },
    data() {
      return {
        
      }
    },
    computed: {
      loginedUser() {
        return this.$store.state.user.user
      }
    },
    beforeRouteEnter (to, from, next) {
      //console.log(`to=${to.name} & from=${from.name}`)
      if(!from.name) {
        axios
          .get("getsession")
          .then((res) => {
            if (res.data.errcode == 0) {
              next(vm => {
                vm.$store.commit("user/SetUser", res.data.user)
                vm.$store.commit("profiles/SetProfiles", res.data.profiles);
                vm.$store.commit("prefecture/SetPrefecture", res.data.prefecture);
              })
            }
            else{
              alert("Unable to connect to server.")
              next(vm => vm.$router.push("/login"))
            }
          })
          .catch((err) => {
            if (err.response && err.response.status == 401)
              next(vm => vm.$router.push("/login"))
            else {
              alert("Unable to connect to server.")
              next(vm => vm.$router.push("/login"))
            }
          });
      }
      else {
          next(vm => vm.isLogined())
      }
    },
    methods: {
      initScrollbar() {
        let isWindows = navigator.platform.startsWith('Win');
        if (isWindows) {
          initScrollbar('sidenav');
        }
      },
      isLogined() {
        if(!this.loginedUser) this.$router.push('/login')
      }
    },
    mounted() {
      this.initScrollbar()
    }
  };
</script>
<style lang="scss">
</style>
