<template>
  <div>
    <!-- Header -->
    <div class="header py-7 py-lg-8 pt-lg-9">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <div class="errcode">404</div>
              <h1 class="text-muted">Ooops!</h1>
              <p class="text-muted">页面不见了</p>
              <router-link to="/dashboard" class="font-weight-bold text-default mt-5">返回控制台</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'not-found'
};
</script>
<style scoped>
  .errcode { 
    font-size: 10rem;
    color: #2dce89;
  }
</style>