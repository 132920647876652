<template>
  <base-nav
    container-classes="container-fluid"
    class="navbar-top border-bottom navbar-expand"
    :class="{'bg-success navbar-dark': type === 'default'}"
  >

    <div class="row align-items-center pl-3 d-none d-md-flex">
      <h6 class="h2 text-white d-inline-block mb-0">{{ $route.name }}</h6>
      <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
        <route-bread-crumb></route-bread-crumb>
      </nav>
    </div>
    <!-- Navbar links -->
    <ul class="navbar-nav align-items-center ml-md-auto">
      <li class="nav-item d-xl-none">
        <!-- Sidenav toggler -->
        <div
          class="pr-3 sidenav-toggler"
          :class="{active: $sidebar.showSidebar, 'sidenav-toggler-dark': type === 'default', 'sidenav-toggler-light': type === 'light'}"
          @click="toggleSidebar"
        >
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
          </div>
        </div>
      </li>
      <li class="nav-item d-sm-none">
        <a class="nav-link" href="#" data-action="search-show" data-target="#navbar-search-main">
          <i class="ni ni-zoom-split-in"></i>
        </a>
      </li>
    </ul>
    <ul class="navbar-nav align-items-center ml-auto ml-md-0">
      <base-dropdown
        menu-on-right
        class="nav-item"
        tag="li"
        title-tag="a"
        title-classes="nav-link pr-0"
      >
        <a href="#" class="nav-link pr-0" @click.prevent slot="title-container">
          <div class="media align-items-center">
            <span class="avatar avatar-sm">
              <!--img alt="Image placeholder" src="img/theme/team-4.jpg"-->
              <i class="ni ni-circle-08" style="top:0;"></i>
            </span>
            <div class="media-body ml-2 d-none d-lg-block">
              <span class="mb-0 text-sm font-weight-bold">{{ loginedUser.username }}</span>
            </div>
          </div>
        </a>

        <template>
          <div class="dropdown-header noti-title">
            <h6 class="text-overflow m-0">{{ loginedUser.username }}</h6>
          </div>
          <a href="javascript:;" @click="openProfiles" class="dropdown-item">
            <i class="ni ni-ui-04"></i>
            <span>账号资料</span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#!" class="dropdown-item text-danger" @click="logout">
            <i class="ni ni-button-power"></i>
            <span>登出</span>
          </a>
        </template>
      </base-dropdown>
    </ul>
  </base-nav>
</template>
<script>
import { mapState } from "vuex";
import { BaseNav } from "@/components";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";

export default {
  components: {
    BaseNav,
    RouteBreadCrumb,
  },
  props: {
    type: {
      type: String,
      default: "default", // default|light
      description:
        "Look of the dashboard navbar. Default (Green) or light (gray)",
    },
  },
  computed: {
    ...mapState({
      loginedUser: (state) => state.user.user || {},
      isSuper: (state) => state.user.user.is_super
    }),
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: "",
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    logout() {
      this.axios
        .get("logout")
        .then((res) => {
          if (res.data.errcode == 0) {
            this.$store.commit("user/SetUser", null);
            this.$router.push("/login");
          } else {
            this.$notify({
              type: "warning",
              message: "Unable to logout at this time.",
            });
          }
        })
        .catch((err) => {
          this.$notify({
            type: "warning",
            message: "Logout failed: " + err.message,
          });
          console.log(err);
        });
    },
    openProfiles() {
      this.$router.push(`/profiles`);
    }
  }
};
</script>
<style scoped>
.avatar {
  background-color: transparent;
}
.avatar-sm {
  font-size: 1.875rem;
}
.avatar i.ni {
  color: #192b4d;
}
</style>
