// initial state
const state = () => ({
    profiles: null
})

const getters = {
    Profiles: (state) => {
        return state.profiles
    }
}

const actions = {
    
}

// update methods
const mutations = {
    SetProfiles (state, profiles) {
        state.profiles = profiles
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
  