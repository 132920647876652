// initial state
const state = () => ({
    user: null
})

const getters = {
    UserInfo: (state) => {
        return state.user
    }
}

const actions = {
    
}

// update methods
const mutations = {
    SetUser (state, user) {
        state.user = user
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
  